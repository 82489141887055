import { Alert, Avatar, List, Skeleton, Table, Tag, Typography } from 'antd';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useOrder } from '../../hooks/use-order';
import { Order } from '../../types/Order';
import { formatPrice } from '../../utils/format-price';
import AccountLayout from '../account/AccountLayout';
import Breadcrumb from '../Breadcrumb';
import MainLayout from '../layout/MainLayout';
import Section from '../Section';
import OrderItem from './OrderItem';
import OrderItemSkeleton from './OrderItemSkeleton';
import OrderStatusTag from './OrderStatus';

const { Text } = Typography;

const TextSkeleton = () => (
  <Skeleton
    className="!mt-[6px] !w-[60px]"
    paragraph={false}
    active
    title={{ width: '100%' }}
    round
  />
);

const OrderDetailPage = (props) => {
  const { handle } = props;
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState<Order>();
  const {
    actions: { getOrder },
  } = useOrder();

  const fetchOrder = async () => {
    try {
      setLoading(true);
      const res = await getOrder(handle);
      setOrder(res);
    } catch (_) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrder();
  }, []);

  const displayOrderId = `#${handle.padStart(7, '0')}`;

  const hasNoPrice = order?.items.some((item) => !item.unit_price);
  return (
    <MainLayout>
      <Breadcrumb
        items={[
          {
            title: 'คำสั่งซื้อ',
            link: '/orders',
          },
          {
            title: displayOrderId,
            link: `/orders/${handle}`,
          },
        ]}
        title={`คำสั่งซื้อ ${displayOrderId}`}
      />
      <Section className="py-6">
        <AccountLayout>
          <div className="p-6 bg-neutral-1 rounded-[6px] shadow-small border-light-divider border">
            <div className="flex items-center mb-[18px]">
              <Text className="!text-xl !font-semibold mr-2">
                {displayOrderId}
              </Text>
              {!loading && <OrderStatusTag value={order?.status} />}
            </div>
            <div className="grid grid-cols-2 gap-4 lg:flex">
              <div className="flex flex-col">
                <Text className="!text-light-secondary mb-1">
                  วันที่สั่งซื่อ
                </Text>
                {loading ? (
                  <TextSkeleton />
                ) : (
                  <Text className="!text-light-title">
                    {DateTime.fromISO(order?.created_at || '').toFormat(
                      'd LLL yyyy'
                    )}
                  </Text>
                )}
              </div>
              <div className="flex flex-col lg:ml-6">
                <Text className="!text-light-secondary mb-1">
                  เวลาที่สั่งซื้อ
                </Text>
                {loading ? (
                  <TextSkeleton />
                ) : (
                  <Text className="!text-light-title">
                    {DateTime.fromISO(order?.created_at || '').toFormat(
                      'HH:mm'
                    )}
                  </Text>
                )}
              </div>
              <div className="flex flex-col lg:ml-6">
                <Text className="!text-light-secondary mb-1">สั่งซื้อโดย</Text>
                {loading ? (
                  <TextSkeleton />
                ) : (
                  <div>
                    <Avatar size={22} className="!text-sm">
                      {order?.customer.first_name.substring(0, 1).toUpperCase()}
                    </Avatar>
                    <Text className="ml-2 !text-sm !text-light-title">
                      {`${order?.customer.first_name}`}
                    </Text>
                  </div>
                )}
              </div>
              <div className="flex flex-col lg:ml-6">
                <Text className="!text-light-secondary mb-1">อีเมล</Text>
                {loading ? (
                  <TextSkeleton />
                ) : (
                  <Text className="!text-light-title">{order?.email}</Text>
                )}
              </div>
              <div className="flex flex-col lg:ml-6">
                <Text className="!text-light-secondary mb-1">เบอร์ติดต่อ</Text>
                {loading ? (
                  <TextSkeleton />
                ) : (
                  <Text className="!text-light-title">
                    {order?.customer.phone}
                  </Text>
                )}
              </div>
            </div>
          </div>
          <div className="mt-3 bg-neutral-1 rounded-[6px] shadow-small border-light-divider border">
            <div className="px-6 py-3 border-b text-lg text-light-title font-semibold border-light-divider">
              รายละเอียดคำสั่งซื้อ
            </div>
            <div className="bg-light-lighter">
              <List
                dataSource={loading ? new Array(2).fill(1) : order?.items}
                renderItem={(item) => {
                  if (loading) return <OrderItemSkeleton />;
                  return <OrderItem item={item} />;
                }}
              />
              <div className="flex justify-between p-6 border-light-divider border-t">
                <Text className="!text-xl !text-light-title !font-semibold">
                  ราคารวม
                </Text>
                {loading ? (
                  <TextSkeleton />
                ) : (
                  <div className="flex !text-xl text-light-title !font-semibold">
                    {hasNoPrice && <span className="text-red-6">*</span>}
                    {`฿ ${formatPrice(order?.total || 0)} THB`}
                  </div>
                )}
              </div>
              {hasNoPrice && (
                <div className="px-6 pb-6">
                  <Alert
                    className="!items-baseline !text-light-title"
                    message="ราคารวมที่แสดงนี้ถูกคำนวณจากสินค้าที่มีการระบุราคาเอาไว้เท่านั้น"
                    showIcon
                  />
                </div>
              )}
            </div>
          </div>
        </AccountLayout>
      </Section>
    </MainLayout>
  );
};

export default OrderDetailPage;

import { Button, Form, InputNumber, Skeleton, Typography } from 'antd';
import { useCart } from '../../hooks/use-cart';
import React, { useState } from 'react';
import { formatPrice } from '../../utils/format-price';
import { OrderItem } from '../../types/Order';
import CartIcon from '../../icons/cart';

const { Text } = Typography;

interface formValue {
  qty: number;
}

const OrderItemSkeleton = () => {
  const {
    actions: { addItem },
  } = useCart();
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm<formValue>();

  const addToCart = async () => {
    setLoading(true);
    try {
      await addItem({
        variant_id: item.variant_id,
        quantity: 1,
        metadata: item.metadata,
      });
    } catch (_) {
      // TODO: handle error
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-4 border-b border-light-divider last:border-0 flex w-full overflow-hidden">
      <div
        className={`w-[80px] h-fit p-1 rounded-[6px] bg-neutral-1 shadow-small border border-light-divider`}
      >
        <div className="w-full pt-[75%] bg-cover bg-center bg-light-divider" />
      </div>
      <div className="px-4 flex flex-col flex-1">
        <Skeleton
          title={false}
          round
          paragraph={{ rows: 3, width: [100, 50, 50] }}
          active
        />
        <Skeleton.Button className="lg:!hidden mt-2" active />
      </div>
      <div className="hidden lg:flex w-[120px] justify-end">
        <Skeleton title={{ width: 50 }} round paragraph={false} active />
      </div>
      <div className="hidden lg:flex items-baseline ml-6 w-[120px] justify-end">
        <Skeleton title={{ width: 50 }} round paragraph={false} active />
      </div>
      <div className="hidden lg:flex ml-6 w-[120px] justify-end">
        <Skeleton title={{ width: 50 }} round paragraph={false} active />
      </div>
      <div className="hidden lg:flex">
        <Skeleton.Button active />
      </div>
    </div>
  );
};

export default OrderItemSkeleton;

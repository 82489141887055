import tailwindConfig from '../../tailwind.config';
import HomeIcon from '../icons/home';
import React from 'react';
import Section from './Section';
import { Typography } from 'antd';
import { Link } from 'gatsby';

const resolveConfig = require('tailwindcss/resolveConfig');
const config = resolveConfig(tailwindConfig);

const { Text, Title } = Typography;

type HeaderType = 'product' | 'collection';

interface BreadcrumbItem {
  title: string;
  link: string;
}
export const BreadcrumbItem = ({
  title,
  link,
}: {
  title: string;
  link: string;
}) => {
  return (
    <>
      <Text className="!text-light-border mx-2">/</Text>
      <Link
        className="!text-light-title last:!text-light-title last:font-medium"
        to={link}
      >
        {title}
      </Link>
    </>
  );
};

const Breadcrumb = ({
  title,
  items,
}: {
  title: string;
  items: BreadcrumbItem[];
}) => {
  return (
    <div className="bg-white border-b border-light-divider shadow-small">
      <Section className="!py-4">
        <div className="flex items-center mb-3">
          <Link to="/">
            <HomeIcon color={config.theme.colors.light.title} />
          </Link>
          {items.map(({ title, link }) => (
            <BreadcrumbItem title={title} link={link} />
          ))}
        </div>
        <Title className="!text-light-title !mb-0" level={3}>
          {title}
        </Title>
      </Section>
    </div>
  );
};

export default Breadcrumb;
